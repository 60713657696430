export const isBrowser = () => typeof window !== "undefined";

export const getLang = () =>
  isBrowser() && window.localStorage.getItem("QuintaliliLang")
    ? JSON.parse(window.localStorage.getItem("QuintaliliLang"))
    : {}

const setLang = (lang) => {
    window.localStorage.setItem("QuintaliliLang", JSON.stringify(lang))
    console.log('langSetTo:', lang);
}
  

export const toggleLang = (currlang) => {

    if (currlang === `ES` ) {
        return setLang({
        language: `ES`
        });
  } else if (currlang === `EN` ) {
        return setLang({
        language: `EN`
        });
  }
}
