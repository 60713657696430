import React from 'react';
import { Link } from 'gatsby';
import { Container, Row, Col, } from 'reactstrap';

import {toggleLang, getLang} from '../services/lang';
import Layout from '../components/Layout';
import * as LNK from '../res/links';
import * as TXT from '../res/strings';
import * as TXTEN from '../res/strings_en';
import { MASTERSUITE_ROOM, ROOFGARDEN_NOCHE, FAMILYSUITE_BED, REDSUNSET, BLUEVIEW_ROOM, BLUEVIEW_ROOM_MOB } from '../res/images';

class Rooms extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: 'ES',
    }

    this.toggleLanguage = this.toggleLanguage.bind(this);
  }

  componentDidMount(){
    if (getLang().language !== this.state.lang) {
      this.setState({lang: getLang().language});
      }
  }

  toggleLanguage(lang) {
    toggleLang(lang);
    if (lang !== this.state.lang) {
      this.setState({lang: lang});
    }
  }

  render() {
    const APP_LANG = this.state.lang;
    return (
      <Layout lang={this.state.lang} toggleLanguage={this.toggleLanguage}>
        <div className="section" id="start" style={{ marginTop: 100 }}>
          <Container>
            <Row>
              <Col className="text-center title">
                <h1>
                  {APP_LANG === 'EN' ? TXTEN.ROOMS.TITLE1 : TXT.ROOMS.TITLE1}
                </h1>
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
                <p>
                  {APP_LANG === 'EN' ? TXTEN.ROOMS.DESCRIPTION : TXT.ROOMS.DESCRIPTION}
                </p>
              </Col>
            </Row>
            <Row className="roomSlice">
              <Col xs="12" md="6">
                <img className="roomImage" src={MASTERSUITE_ROOM} alt="Quinta Lili Room1" />
              </Col>
              <Col xs="12" md="6" className="d-flex align-items-start flex-column">
                <div className="title">
                  <h2>
                    {APP_LANG === 'EN' ? TXTEN.ROOMS.ROOM_NAME1 : TXT.ROOMS.ROOM_NAME1}
                  </h2>
                </div>
                <p>
                  {APP_LANG === 'EN' ? TXTEN.ROOMS.ROOM_DESCRIPTION1 : TXT.ROOMS.ROOM_DESCRIPTION1}
                </p>
                <Link to={LNK.ROOMS.ROOM1} className="mt-auto">
                  <div className="btnAction">
                    {APP_LANG === 'EN' ? TXTEN.BUTTON.BTN_GO : TXT.BUTTON.BTN_GO}
                  </div>
                </Link>
              </Col>
            </Row>
            <span className="separator" />
            <Row className="roomSlice">
              <Col xs="12" md="6">
                <img className="roomImage" src={ROOFGARDEN_NOCHE} alt="Quinta Lili Room2" />
              </Col>
              <Col xs="12" md="6" className="d-flex align-items-start flex-column">
                <div className="title">
                  <h2>
                    {APP_LANG === 'EN' ? TXTEN.ROOMS.ROOM_NAME2 : TXT.ROOMS.ROOM_NAME2}
                  </h2>
                </div>
                <p>
                  {APP_LANG === 'EN' ? TXTEN.ROOMS.ROOM_DESCRIPTION2 : TXT.ROOMS.ROOM_DESCRIPTION2}
                </p>
                <Link to={LNK.ROOMS.ROOM2} className="mt-auto">
                  <div className="btnAction">
                    {APP_LANG === 'EN' ? TXTEN.BUTTON.BTN_GO : TXT.BUTTON.BTN_GO}
                  </div>
                </Link>
              </Col>
            </Row>
            <span className="separator" />
            <Row className="roomSlice">
              <Col xs="12" md="6">
                <img className="roomImage" src={FAMILYSUITE_BED} alt="Quinta Lili Room3" />
              </Col>
              <Col xs="12" md="6" className="d-flex align-items-start flex-column">
                <div className="title">
                  <h2>
                    {APP_LANG === 'EN' ? TXTEN.ROOMS.ROOM_NAME3 : TXT.ROOMS.ROOM_NAME3}
                  </h2>
                </div>
                <p>
                  {APP_LANG === 'EN' ? TXTEN.ROOMS.ROOM_DESCRIPTION3 : TXT.ROOMS.ROOM_DESCRIPTION3}
                </p>
                <Link to={LNK.ROOMS.ROOM3} className="mt-auto">
                  <div className="btnAction">
                    {APP_LANG === 'EN' ? TXTEN.BUTTON.BTN_GO : TXT.BUTTON.BTN_GO}
                  </div>
                </Link>
              </Col>
            </Row>
            <span className="separator" />
            <Row className="roomSlice">
              <Col xs="12" md="6">
                <img className="roomImage" src={REDSUNSET} alt="Quinta Lili Room4" />
              </Col>
              <Col xs="12" md="6" className="d-flex align-items-start flex-column">
                <div className="title">
                  <h2>
                    {APP_LANG === 'EN' ? TXTEN.ROOMS.ROOM_NAME4 : TXT.ROOMS.ROOM_NAME4}
                  </h2>
                </div>
                <p>
                  {APP_LANG === 'EN' ? TXTEN.ROOMS.ROOM_DESCRIPTION4 : TXT.ROOMS.ROOM_DESCRIPTION4}
                </p>
                <Link to={LNK.ROOMS.ROOM4} className="mt-auto">
                  <div className="btnAction">
                    {APP_LANG === 'EN' ? TXTEN.BUTTON.BTN_GO : TXT.BUTTON.BTN_GO}
                  </div>
                </Link>
              </Col>
            </Row>
            <span className="separator" />
            <Row className="roomSlice">
              <Col xs="12" md="6">
                <img className="roomImage" src={BLUEVIEW_ROOM} alt="Quinta Lili Room5" />
              </Col>
              <Col xs="12" md="6" className="d-flex align-items-start flex-column">
                <div className="title">
                  <h2>
                    {APP_LANG === 'EN' ? TXTEN.ROOMS.ROOM_NAME5 : TXT.ROOMS.ROOM_NAME5}
                  </h2>
                </div>
                <p>
                  {APP_LANG === 'EN' ? TXTEN.ROOMS.ROOM_DESCRIPTION5 : TXT.ROOMS.ROOM_DESCRIPTION5}
                </p>
                <Link to={LNK.ROOMS.ROOM5} className="mt-auto">
                  <div className="btnAction">
                    {APP_LANG === 'EN' ? TXTEN.BUTTON.BTN_GO : TXT.BUTTON.BTN_GO}
                  </div>
                </Link>
              </Col>
            </Row>
            <span className="separator" />
            <Row className="roomSlice">
              <Col xs="12" md="6">
                <img className="roomImage" src={BLUEVIEW_ROOM_MOB} alt="Quinta Lili Room6" />
              </Col>
              <Col xs="12" md="6" className="d-flex align-items-start flex-column">
                <div className="title">
                  <h2>
                    {APP_LANG === 'EN' ? TXTEN.ROOMS.ROOM_NAME6 : TXT.ROOMS.ROOM_NAME6}
                  </h2>
                </div>
                <p>
                  {APP_LANG === 'EN' ? TXTEN.ROOMS.ROOM_DESCRIPTION6 : TXT.ROOMS.ROOM_DESCRIPTION6}
                </p>
                <Link to={LNK.ROOMS.ROOM6} className="mt-auto">
                  <div className="btnAction">
                    {APP_LANG === 'EN' ? TXTEN.BUTTON.BTN_GO : TXT.BUTTON.BTN_GO}
                  </div>
                </Link>
              </Col>
            </Row>
            <Row>
              <Col xl="12" className="text-center">
                <a href="#start">
                  <div className="btnAction mt-auto">
                    {APP_LANG === 'EN' ? TXTEN.BUTTON.BTN_BACK_UP : TXT.BUTTON.BTN_BACK_UP}
                  </div>
                </a>
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    );
  }

}

export default Rooms;