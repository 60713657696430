import IMG_BLUEVIEW_BANO from '../images/blueview/blueview-bano.jpg';
import IMG_BLUEVIEW_ROOM_NIGHT from '../images/blueview/blueview-room-night.jpg';
import IMG_BLUEVIEW_ROOM from '../images/blueview/blueview-room.jpg';
import IMG_FAMILYSUITE_BED from '../images/familysuite/familysuite-bed.jpg';
import IMG_FAMILYSUITE_BED2 from '../images/familysuite/familysuite-bed2.jpg';
import IMG_FAMILYSUITE_ESCALERAS from '../images/familysuite/familysuite-escaleras.jpg';
import IMG_FAMILYSUITE_JACUZZI from '../images/familysuite/familysuite-jacuzzi.jpg';
import IMG_FAMILYSUITE_SOFA from '../images/familysuite/familysuite-sofa.jpg';
import IMG_FAMILYSUITE_TERRAZA from '../images/familysuite/familysuite-terraza.jpg';
import IMG_INSTAL_ALBERCA from '../images/instalaciones/instalaciones-alberca.jpg';
import IMG_INSTAL_ALBERCA_NOCHE from '../images/instalaciones/instalaciones-alberca-noche.jpg';
import IMG_INSTAL_ALBERCA_DIA from '../images/instalaciones/instalaciones-alberca-dia.jpg';
import IMG_INSTAL_ASADOR from '../images/instalaciones/instalaciones-asador.jpg';
import IMG_INSTAL_BAR from '../images/instalaciones/instalaciones-bar.jpg';
import IMG_INSTAL_COCINA from '../images/instalaciones/instalaciones-cocina.jpg';
import IMG_INSTAL_FRUTA_NOCHE from '../images/instalaciones/instalaciones-fruta-noche.jpg';
import IMG_INSTAL_ALBERCA2 from '../images/instalaciones/instalaciones-quinta-lili-alberca.jpg';
import IMG_INSTAL_VISTA_LAGO from '../images/instalaciones/instalaciones-vista-lago-tequesquitengo.jpg';
import IMG_INSTAL_FRONT from '../images/instalaciones/Quinta-Lili-Frente.jpg';
import IMG_MASTERSUITE_BANO from '../images/mastersuite/mastersuite-bano.jpg';
import IMG_MASTERSUITE_BED from '../images/mastersuite/mastersuite-bed.jpg';
import IMG_MASTERSUITE_PASILLO from '../images/mastersuite/mastersuite-pasillo-puente.jpg';
import IMG_MASTERSUITE_ROOM from '../images/mastersuite/mastersuite-room.jpg';
import IMG_MASTERSUITE_TERRAZA from '../images/mastersuite/mastersuite-terraza.jpg';
import IMG_REDSUNSET_BED from '../images/redsunset/redsunset-bed-dia.jpg';
import IMG_REDSUNSET_BED_NOCHE from '../images/redsunset/redsunset-bed-noche.jpg';
import IMG_REDSUNSET from '../images/redsunset/redsunset.jpg';
import IMG_ROOFGARDEN_BARRA from '../images/roofgarden/roofgarden-barra.jpg';
import IMG_ROOFGARDEN_JACUZZI from '../images/roofgarden/roofgarden-jacuzzi.jpg';
import IMG_ROOFGARDEN_JACUZZI2 from '../images/roofgarden/roofgarden-jacuzzi2.jpg';
import IMG_ROOFGARDEN_NOCHE from '../images/roofgarden/roofgarden-noche.jpg';
import IMG_ROOFGARDEN_VISTA from '../images/roofgarden/roofgarden-vista.jpg';
import IMG_SERVICIOS_ACCESO_LAGO from '../images/servicios/servicios-acceso-lago.jpg';
import IMG_SERVICIOS_ACCESO_LAGO2 from '../images/servicios/servicios-acceso-lago-2.jpg';
import IMG_SERVICIOS_LANCHAS from '../images/servicios/servicios-lanchas.jpg';
import IMG_TEQUES1 from '../images/tequesquitengo/Tequesquitengo1.jpg';
import IMG_TEQUES2 from '../images/tequesquitengo/Tequesquitengo2.jpg';
import IMG_TEQUES3 from '../images/tequesquitengo/Tequesquitengo3.jpg';
import IMG_TEQUES4 from '../images/tequesquitengo/Tequesquitengo4.jpg';
import IMG_TEQUES5 from '../images/tequesquitengo/Tequesquitengo5.jpg';
import IMG_TEQUES6 from '../images/tequesquitengo/Tequesquitengo6.jpg';
import IMG_TEQUES7 from '../images/tequesquitengo/Tequesquitengo7.jpg';
import IMG_TEQUES8 from '../images/tequesquitengo/Tequesquitengo8.jpg';
import IMG_TEQUES9 from '../images/tequesquitengo/Tequesquitengo9.jpg';


import IMG_MAP from '../images/Quinta-lili-Tequesquitengo-map.jpg';
export const MAP = IMG_MAP;

import IMG_ANUNCIO_LARGE from '../images/anuncio-large.jpg';
import IMG_ANUNCIO_SMALL from '../images/anuncio-small.jpg';
export const ANUNCIO_LARGE = IMG_ANUNCIO_LARGE;
export const ANUNCIO_SMALL = IMG_ANUNCIO_SMALL;

export const BLUEVIEW_BANO = IMG_BLUEVIEW_BANO;
export const BLUEVIEW_ROOM_NIGHT = IMG_BLUEVIEW_ROOM_NIGHT;
export const BLUEVIEW_ROOM = IMG_BLUEVIEW_ROOM;
export const FAMILYSUITE_BED = IMG_FAMILYSUITE_BED;
export const FAMILYSUITE_BED2 = IMG_FAMILYSUITE_BED2;
export const FAMILYSUITE_ESCALERAS = IMG_FAMILYSUITE_ESCALERAS;
export const FAMILYSUITE_JACUZZI = IMG_FAMILYSUITE_JACUZZI;
export const FAMILYSUITE_SOFA = IMG_FAMILYSUITE_SOFA;
export const FAMILYSUITE_TERRAZA = IMG_FAMILYSUITE_TERRAZA;
export const INSTAL_ALBERCA = IMG_INSTAL_ALBERCA;
export const INSTAL_ALBERCA_NOCHE = IMG_INSTAL_ALBERCA_NOCHE;
export const INSTAL_ALBERCA_DIA = IMG_INSTAL_ALBERCA_DIA;
export const INSTAL_ASADOR = IMG_INSTAL_ASADOR;
export const INSTAL_BAR = IMG_INSTAL_BAR;
export const INSTAL_COCINA = IMG_INSTAL_COCINA;
export const INSTAL_FRUTA_NOCHE = IMG_INSTAL_FRUTA_NOCHE;
export const INSTAL_ALBERCA2 = IMG_INSTAL_ALBERCA2;
export const INSTAL_VISTA_LAGO = IMG_INSTAL_VISTA_LAGO;
export const INSTAL_FRONT = IMG_INSTAL_FRONT;
export const MASTERSUITE_BANO = IMG_MASTERSUITE_BANO;
export const MASTERSUITE_BED = IMG_MASTERSUITE_BED;
export const MASTERSUITE_PASILLO = IMG_MASTERSUITE_PASILLO;
export const MASTERSUITE_ROOM = IMG_MASTERSUITE_ROOM;
export const MASTERSUITE_TERRAZA = IMG_MASTERSUITE_TERRAZA;
export const REDSUNSET_BED = IMG_REDSUNSET_BED;
export const REDSUNSET_BED_NOCHE = IMG_REDSUNSET_BED_NOCHE;
export const REDSUNSET = IMG_REDSUNSET;
export const ROOFGARDEN_BARRA = IMG_ROOFGARDEN_BARRA;
export const ROOFGARDEN_JACUZZI = IMG_ROOFGARDEN_JACUZZI;
export const ROOFGARDEN_JACUZZI2 = IMG_ROOFGARDEN_JACUZZI2;
export const ROOFGARDEN_NOCHE = IMG_ROOFGARDEN_NOCHE;
export const ROOFGARDEN_VISTA = IMG_ROOFGARDEN_VISTA;
export const SERVICIOS_ACCESO_LAGO = IMG_SERVICIOS_ACCESO_LAGO;
export const SERVICIOS_ACCESO_LAGO2 = IMG_SERVICIOS_ACCESO_LAGO2;
export const SERVICIOS_LANCHAS = IMG_SERVICIOS_LANCHAS;
export const TEQUES1 = IMG_TEQUES1;
export const TEQUES2 = IMG_TEQUES2;
export const TEQUES3 = IMG_TEQUES3;
export const TEQUES4 = IMG_TEQUES4;
export const TEQUES5 = IMG_TEQUES5;
export const TEQUES6 = IMG_TEQUES6;
export const TEQUES7 = IMG_TEQUES7;
export const TEQUES8 = IMG_TEQUES8;
export const TEQUES9 = IMG_TEQUES9;

import IMG_BLUEVIEW_BANO_MOB from '../images/blueview/blueview-bano_mob.jpg';
import IMG_BLUEVIEW_ROOM_NIGHT_MOB from '../images/blueview/blueview-room-night_mob.jpg';
import IMG_BLUEVIEW_ROOM_MOB from '../images/blueview/blueview-room_mob.jpg';
import IMG_FAMILYSUITE_BED_MOB from '../images/familysuite/familysuite-bed_mob.jpg';
import IMG_FAMILYSUITE_BED2_MOB from '../images/familysuite/familysuite-bed2_mob.jpg';
import IMG_FAMILYSUITE_ESCALERAS_MOB from '../images/familysuite/familysuite-escaleras_mob.jpg';
import IMG_FAMILYSUITE_JACUZZI_MOB from '../images/familysuite/familysuite-jacuzzi_mob.jpg';
import IMG_FAMILYSUITE_SOFA_MOB from '../images/familysuite/familysuite-sofa_mob.jpg';
import IMG_FAMILYSUITE_TERRAZA_MOB from '../images/familysuite/familysuite-terraza_mob.jpg';
import IMG_INSTAL_ALBERCA_MOB from '../images/instalaciones/instalaciones-alberca_mob.jpg';
import IMG_INSTAL_ALBERCA_NOCHE_MOB from '../images/instalaciones/instalaciones-alberca-noche_mob.jpg';
import IMG_INSTAL_ALBERCA_DIA_MOB from '../images/instalaciones/instalaciones-alberca-dia_mob.jpg';
import IMG_INSTAL_ASADOR_MOB from '../images/instalaciones/instalaciones-asador_mob.jpg';
import IMG_INSTAL_BAR_MOB from '../images/instalaciones/instalaciones-bar_mob.jpg';
import IMG_INSTAL_COCINA_MOB from '../images/instalaciones/instalaciones-cocina_mob.jpg';
import IMG_INSTAL_FRUTA_NOCHE_MOB from '../images/instalaciones/instalaciones-fruta-noche_mob.jpg';
import IMG_INSTAL_ALBERCA2_MOB from '../images/instalaciones/instalaciones-quinta-lili-alberca_mob.jpg';
import IMG_INSTAL_VISTA_LAGO_MOB from '../images/instalaciones/instalaciones-vista-lago-tequesquitengo_mob.jpg';
import IMG_INSTAL_FRONT_MOB from '../images/instalaciones/Quinta-Lili-Frente_mob.jpg';
import IMG_MASTERSUITE_BANO_MOB from '../images/mastersuite/mastersuite-bano_mob.jpg';
import IMG_MASTERSUITE_BED_MOB from '../images/mastersuite/mastersuite-bed_mob.jpg';
import IMG_MASTERSUITE_PASILLO_MOB from '../images/mastersuite/mastersuite-pasillo-puente_mob.jpg';
import IMG_MASTERSUITE_ROOM_MOB from '../images/mastersuite/mastersuite-room_mob.jpg';
import IMG_MASTERSUITE_TERRAZA_MOB from '../images/mastersuite/mastersuite-terraza_mob.jpg';
import IMG_REDSUNSET_BED_MOB from '../images/redsunset/redsunset-bed-dia.jpg';
import IMG_REDSUNSET_BED_NOCHE_MOB from '../images/redsunset/redsunset-bed-noche.jpg';
import IMG_REDSUNSET_MOB from '../images/redsunset/redsunset.jpg';
import IMG_ROOFGARDEN_BARRA_MOB from '../images/roofgarden/roofgarden-barra_mob.jpg';
import IMG_ROOFGARDEN_JACUZZI_MOB from '../images/roofgarden/roofgarden-jacuzzi_mob.jpg';
import IMG_ROOFGARDEN_JACUZZI2_MOB from '../images/roofgarden/roofgarden-jacuzzi2_mob.jpg';
import IMG_ROOFGARDEN_NOCHE_MOB from '../images/roofgarden/roofgarden-noche_mob.jpg';
import IMG_ROOFGARDEN_VISTA_MOB from '../images/roofgarden/roofgarden-vista_mob.jpg';
import IMG_SERVICIOS_ACCESO_LAGO_MOB from '../images/servicios/servicios-acceso-lago.jpg';
import IMG_SERVICIOS_ACCESO_LAGO2_MOB from '../images/servicios/servicios-acceso-lago-2.jpg';
import IMG_SERVICIOS_LANCHAS_MOB from '../images/servicios/servicios-lanchas.jpg';

export const BLUEVIEW_BANO_MOB = IMG_BLUEVIEW_BANO_MOB;
export const BLUEVIEW_ROOM_NIGHT_MOB = IMG_BLUEVIEW_ROOM_NIGHT_MOB;
export const BLUEVIEW_ROOM_MOB = IMG_BLUEVIEW_ROOM_MOB;
export const FAMILYSUITE_BED_MOB = IMG_FAMILYSUITE_BED_MOB;
export const FAMILYSUITE_BED2_MOB = IMG_FAMILYSUITE_BED2_MOB;
export const FAMILYSUITE_ESCALERAS_MOB = IMG_FAMILYSUITE_ESCALERAS_MOB;
export const FAMILYSUITE_JACUZZI_MOB = IMG_FAMILYSUITE_JACUZZI_MOB;
export const FAMILYSUITE_SOFA_MOB = IMG_FAMILYSUITE_SOFA_MOB;
export const FAMILYSUITE_TERRAZA_MOB = IMG_FAMILYSUITE_TERRAZA_MOB;
export const INSTAL_ALBERCA_MOB = IMG_INSTAL_ALBERCA_MOB;
export const INSTAL_ALBERCA_NOCHE_MOB = IMG_INSTAL_ALBERCA_NOCHE_MOB;
export const INSTAL_ALBERCA_DIA_MOB = IMG_INSTAL_ALBERCA_DIA_MOB;
export const INSTAL_ASADOR_MOB = IMG_INSTAL_ASADOR_MOB;
export const INSTAL_BAR_MOB = IMG_INSTAL_BAR_MOB;
export const INSTAL_COCINA_MOB = IMG_INSTAL_COCINA_MOB;
export const INSTAL_FRUTA_NOCHE_MOB = IMG_INSTAL_FRUTA_NOCHE_MOB;
export const INSTAL_ALBERCA2_MOB = IMG_INSTAL_ALBERCA2_MOB;
export const INSTAL_VISTA_LAGO_MOB = IMG_INSTAL_VISTA_LAGO_MOB;
export const INSTAL_FRONT_MOB = IMG_INSTAL_FRONT_MOB;
export const MASTERSUITE_BANO_MOB = IMG_MASTERSUITE_BANO_MOB;
export const MASTERSUITE_BED_MOB = IMG_MASTERSUITE_BED_MOB;
export const MASTERSUITE_PASILLO_MOB = IMG_MASTERSUITE_PASILLO_MOB;
export const MASTERSUITE_ROOM_MOB = IMG_MASTERSUITE_ROOM_MOB;
export const MASTERSUITE_TERRAZA_MOB = IMG_MASTERSUITE_TERRAZA_MOB;
export const REDSUNSET_BED_MOB = IMG_REDSUNSET_BED_MOB;
export const REDSUNSET_BED_NOCHE_MOB = IMG_REDSUNSET_BED_NOCHE_MOB;
export const REDSUNSET_MOB = IMG_REDSUNSET_MOB;
export const ROOFGARDEN_BARRA_MOB = IMG_ROOFGARDEN_BARRA_MOB;
export const ROOFGARDEN_JACUZZI_MOB = IMG_ROOFGARDEN_JACUZZI_MOB;
export const ROOFGARDEN_JACUZZI2_MOB = IMG_ROOFGARDEN_JACUZZI2_MOB;
export const ROOFGARDEN_NOCHE_MOB = IMG_ROOFGARDEN_NOCHE_MOB;
export const ROOFGARDEN_VISTA_MOB = IMG_ROOFGARDEN_VISTA_MOB;
export const SERVICIOS_ACCESO_LAGO_MOB = IMG_SERVICIOS_ACCESO_LAGO_MOB;
export const SERVICIOS_ACCESO_LAGO2_MOB = IMG_SERVICIOS_ACCESO_LAGO2_MOB;
export const SERVICIOS_LANCHAS_MOB = IMG_SERVICIOS_LANCHAS_MOB;